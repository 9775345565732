body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.chakra-menu__menu-list,
.chakra-menu__menuitem {
  background: #1e1e1e !important;
}

.mobile_menu .chakra-menu__menu-button[aria-label="Options"] {
  background: #fff !important;
}

.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}
